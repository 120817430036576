import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import project1Image from '../assets/house-1/1.jpg'; // Replace with the first image for Project 1
import project2Image from '../assets/house-2/1.jpg'; // Replace with the first image for Project 2

const Projects = () => {
  const projectsData = [
    {
      id: 1,
      title: 'Kasesalu tee 1',
      image: project1Image,
      description: 'Detsembris 2024 valmiv 5-toaline eramaja Haage järve lähistel.',
      link: '/projects/kasesalu-tee-1',
    },
    {
      id: 2,
      title: 'Kasesalu tee 3',
      image: project2Image,
      description: 'Kevadel 2025 valmiv 5-toaline eramaja Haage järve lähistel.',
      link: '/projects/kasesalu-tee-3',
    },
  ];

  return (
    <ProjectsContainer>
      <SectionTitle>Meie projektid</SectionTitle>
      <ProjectsGrid>
        {projectsData.map((project) => (
          <motion.div
            key={project.id}
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
          >
            <ProjectCard whileHover={{ scale: 1.05 }}>
              <Link to={project.link}>
                <ProjectImage src={project.image} alt={project.title} />
                <ProjectInfo>
                  <h3>{project.title}</h3>
                  <p>{project.description}</p>
                </ProjectInfo>
              </Link>
            </ProjectCard>
          </motion.div>
        ))}
      </ProjectsGrid>
    </ProjectsContainer>
  );
};

export default Projects;

/* Styling */
const ProjectsContainer = styled.div`
  padding: 50px;
  background-color: #f7f7f7;
`;

const SectionTitle = styled.h2`
  font-size: 36px;
  text-align: center;
  margin-bottom: 50px;
  color: #333;
`;

const ProjectsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ProjectCard = styled(motion.div)`
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  a {
    text-decoration: none;
    color: inherit;
  }

  &:hover {
    transform: translateY(-5px);
  }
`;

const ProjectImage = styled.img`
  width: 100%;
  height: 250px;
  object-fit: cover;
`;

const ProjectInfo = styled.div`
  padding: 20px;

  h3 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #333;
  }

  p {
    font-size: 16px;
    color: #666;
  }
`;
