import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FaMapMarkerAlt, FaCalendarAlt, FaHome, FaMoneyBillWave, FaChevronLeft, FaChevronRight } from 'react-icons/fa';

// Import individual images from house-1 folder
import img1 from '../assets/house-1/1.jpg';
import img2 from '../assets/house-1/2.jpg';
import img3 from '../assets/house-1/3.jpg';
import img4 from '../assets/house-1/4.png';
import img5 from '../assets/house-1/5.png';
import img6 from '../assets/house-1/6.png';
import img7 from '../assets/house-1/7.png';
import img8 from '../assets/house-1/8.png';
import img9 from '../assets/house-1/9.JPG';

const ProjectDetail1 = () => {
  const images = [img1, img2, img3, img4, img5, img6, img7, img8, img9];

  // State for the modal and current image index
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  // Open modal with the selected image
  const openModal = (index) => {
    setPhotoIndex(index);
    setIsModalOpen(true);
  };

  // Close modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Go to the previous image
  const previousImage = () => {
    setPhotoIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  // Go to the next image
  const nextImage = () => {
    setPhotoIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  // Keyboard navigation
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (isModalOpen) {
        if (e.key === 'ArrowLeft') {
          previousImage();
        } else if (e.key === 'ArrowRight') {
          nextImage();
        } else if (e.key === 'Escape') {
          closeModal();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isModalOpen]);

  const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.8 } },
  };

  return (
    <motion.div initial="hidden" animate="visible" variants={fadeIn}>
      <ProjectDetailContainer>
        <ProjectTitle>Kasesalu tee 1</ProjectTitle>
        <ProjectContent>
          <ProjectInfo>
            <InfoItem>
              <IconWrapper>
                <FaMapMarkerAlt />
              </IconWrapper>
              <div>
                <InfoTitle>Asukoht</InfoTitle>
                <p>Haage järve lähedal, Tartu maakond</p>
              </div>
            </InfoItem>

            <InfoItem>
              <IconWrapper>
                <FaCalendarAlt />
              </IconWrapper>
              <div>
                <InfoTitle>Valmimisaeg</InfoTitle>
                <p>Detsember 2024</p>
              </div>
            </InfoItem>

            <InfoItem>
              <IconWrapper>
                <FaHome />
              </IconWrapper>
              <div>
                <InfoTitle>Detailid</InfoTitle>
                <p>
                  5-toaline maja, 3 magamistuba, kabinet, avatud köögiga elutuba, kaks vannituba, saun ja suur aed.
                </p>
              </div>
            </InfoItem>

            <InfoItem>
              <IconWrapper>
                <FaMoneyBillWave />
              </IconWrapper>
              <div>
                <InfoTitle>Hind</InfoTitle>
                <p>399,000 EUR</p>
              </div>
            </InfoItem>
          </ProjectInfo>

          <DescriptionWrapper>
            <h3>Kirjeldus</h3>
            <p>
              See kaasaegne 5-toaline eramaja pakub suurepärast elukohta vaikses ja looduskaunis piirkonnas. Maja on kujundatud energiasäästlikke lahendusi silmas pidades, pakkudes mugavust ja kaasaegsust. 
              Avatud elutuba, suur köögipind ja suured aknad tagavad valgusküllase ja avara elamise. Ideaalne kodu peredele, kes soovivad rahulikku ja mugavat elu.
            </p>
          </DescriptionWrapper>
        </ProjectContent>

        <ProjectImages>
          {images.map((img, index) => (
            <motion.div key={index} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: index * 0.2 }}>
              <ProjectImage src={img} alt={`Kasesalu tee 1 - image ${index + 1}`} onClick={() => openModal(index)} />
            </motion.div>
          ))}
        </ProjectImages>
        <BackLink to="/projects">← Tagasi projektide juurde</BackLink>

        {/* Modal for image navigation */}
        {isModalOpen && (
          <ModalOverlay>
            <ModalContent>
              <CloseButton onClick={closeModal}>×</CloseButton>
              <ModalImage src={images[photoIndex]} alt={`Image ${photoIndex + 1}`} />
              <NavButtonLeft onClick={previousImage}>
                <FaChevronLeft />
              </NavButtonLeft>
              <NavButtonRight onClick={nextImage}>
                <FaChevronRight />
              </NavButtonRight>
            </ModalContent>
          </ModalOverlay>
        )}
      </ProjectDetailContainer>
    </motion.div>
  );
};

export default ProjectDetail1;

/* Styling */
const ProjectDetailContainer = styled.div`
  padding: 50px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: 'Roboto', sans-serif;
`;

const ProjectTitle = styled.h2`
  font-size: 42px;
  margin-bottom: 20px;
  font-family: 'Poppins', sans-serif;
  color: #333;
  text-align: center;
`;

const ProjectContent = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 40px;
`;

const ProjectInfo = styled.div`
  flex: 0.48;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const InfoItem = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div`
  font-size: 24px;
  color: #ff7b00;
  margin-right: 15px;
`;

const InfoTitle = styled.h4`
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 5px;
  color: #333;
`;

const DescriptionWrapper = styled.div`
  flex: 0.48;
  h3 {
    font-size: 24px;
    color: #ff7b00;
    margin-bottom: 10px;
    font-family: 'Poppins', sans-serif;
  }

  p {
    font-size: 18px;
    line-height: 1.8;
    color: #555;
  }
`;

const ProjectImages = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ProjectImage = styled.img`
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
  transition: opacity 0.5s ease-in-out;
  cursor: pointer;
`;

/* Modal Styling */
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  position: relative;
  max-width: 80%;
  max-height: 80%;
`;

const ModalImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 10px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  color: white;
  font-size: 30px;
  cursor: pointer;
  z-index: 1001;
`;

const NavButtonLeft = styled.button`
  position: absolute;
  top: 50%;
  left: 10px;
  background: none;
  border: none;
  color: white;
  font-size: 30px;
  cursor: pointer;
  z-index: 1001;
`;

const NavButtonRight = styled.button`
  position: absolute;
  top: 50%;
  right: 10px;
  background: none;
  border: none;
  color: white;
  font-size: 30px;
  cursor: pointer;
  z-index: 1001;
`;

const BackLink = styled(Link)`
  display: inline-block;
  margin-top: 30px;
  text-decoration: none;
  font-size: 16px;
  color: #ff7b00;
  &:hover {
    text-decoration: underline;
  }
`;
