import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FaCheckCircle } from 'react-icons/fa'; // Icons for core values
import heroImage from '../assets/about-hero.jpg';  // Replace with your hero image
import placeholder1 from '../assets/placeholder1.jpg';  // Replace with real estate images
import placeholder2 from '../assets/placeholder2.jpg';
import placeholder3 from '../assets/placeholder3.jpg';
import placeholder4 from '../assets/placeholder4.jpg';

const AboutUs = () => {
  return (
    <AboutUsContainer>
      {/* Hero Section */}
      <HeroSection>
        <Overlay />
        <HeroContent>
          <motion.h1 initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
            Meist
          </motion.h1>
          <motion.p initial={{ opacity: 0, y: 50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 1 }}>
            Toro Kinnisvara on pühendunud tipptasemel kinnisvarateenuste pakkumisele ning usaldusväärsetele lahendustele, mis 
            aitavad klientidel leida ja arendada oma unistuste kodu.
          </motion.p>
        </HeroContent>
      </HeroSection>

      {/* Company Story Section with Parallax Background */}
      <CompanyStory>
        <ParallaxBackground>
          <img src={placeholder1} alt="Placeholder 1" />
          <img src={placeholder2} alt="Placeholder 2" />
          <img src={placeholder3} alt="Placeholder 3" />
          <img src={placeholder4} alt="Placeholder 4" />
        </ParallaxBackground>
        <StoryOverlay />
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
        >
          <StoryContent>
            <SectionTitle>Meie Lugu</SectionTitle>
            <p>
              Toro Kinnisvara on tegutsenud kinnisvaraarenduse valdkonnas üle 20 aasta. Meie meeskond koosneb kogenud 
              professionaalidest, kes on pühendunud kvaliteetsete kinnisvarateenuste pakkumisele. Alates meie esimestest projektidest 
              kuni tänapäevani, oleme keskendunud parimate kodude loomisele ja pikaajalise väärtuse pakkumisele.
            </p>
            <p>
              Meie eesmärk on muuta kinnisvara ostmine ja arendamine lihtsaks ning nauditavaks protsessiks. Oleme uhked oma 
              läbipaistvuse, usaldusväärsuse ja innovaatiliste lahenduste üle, mis vastavad klientide vajadustele.
            </p>
          </StoryContent>
        </motion.div>
      </CompanyStory>

      {/* Core Values Section */}
      <CoreValuesSection>
        <SectionTitle>Meie Tugevused</SectionTitle>
        <CoreValuesGrid>
          {coreValues.map((value, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, scale: 0.8 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
              viewport={{ once: true }}
            >
              <CoreValueCard>
                <FaCheckCircle style={{ color: '#ff7b00', fontSize: '30px', marginBottom: '10px' }} />
                <h3>{value.title}</h3>
                <p>{value.description}</p>
              </CoreValueCard>
            </motion.div>
          ))}
        </CoreValuesGrid>
      </CoreValuesSection>

      {/* Team Section */}
      <TeamSection>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
        >
          <SectionTitle>Meie Meeskond</SectionTitle>
          <p>
            Meie meeskond koosneb kinnisvaraturu spetsialistidest, kes on pühendunud parimate lahenduste pakkumisele. Igaüks 
            meie meeskonnast mängib olulist rolli klientide ootuste ületamisel.
          </p>
        </motion.div>
      </TeamSection>
    </AboutUsContainer>
  );
};

const coreValues = [
  { title: 'Professionaalsus', description: 'Pühendunud meeskond, kellel on üle 20-aastane kogemus kinnisvaraturul.' },
  { title: 'Läbipaistvus', description: 'Selged ja ausad protsessid alates planeerimisest kuni projekti lõppemiseni.' },
  { title: 'Innovatsioon', description: 'Kasutame kaasaegseid tehnoloogiaid ja lahendusi, et pakkuda parimat teenust.' },
  { title: 'Kliendikesksus', description: 'Kuulame ja mõistame oma kliente, et pakkuda neile parimaid lahendusi.' }
];

export default AboutUs;

/* Styling */
const AboutUsContainer = styled.div`
  font-family: 'Arial', sans-serif;
`;

const HeroSection = styled.section`
  width: 100%;
  height: 50vh;
  background-image: url(${heroImage});
  background-position: center;
  background-size: cover;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Dark overlay for better text readability */
`;

const HeroContent = styled.div`
  padding: 0 20px;
  z-index: 1; /* Ensure content appears on top of the overlay */

  h1 {
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #fff; /* Text color adjusted for better readability */
  }

  p {
    font-size: 18px;
    line-height: 1.6;
    color: #fff; /* Text color adjusted for better readability */
  }
`;

/* Parallax Image Collage behind the Story */
const ParallaxBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  gap: 10px;
  overflow: hidden;
  z-index: -1;
  
  img {
    width: 25%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
`;

/* Semi-transparent overlay to improve readability */
const StoryOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8); /* White overlay for contrast */
  z-index: 0;
`;

const CompanyStory = styled.section`
  padding: 100px 50px;
  position: relative;
  background-color: transparent;
`;

const SectionTitle = styled.h2`
  font-size: 36px;
  color: #333;
  text-align: center;
  margin-bottom: 30px;
`;

const StoryContent = styled.div`
  max-width: 900px;
  margin: 0 auto;
  position: relative;
  z-index: 1;

  p {
    font-size: 18px;
    line-height: 1.8;
    margin-bottom: 20px;
    color: #555;
  }
`;

const CoreValuesSection = styled.section`
  padding: 50px;
`;

const CoreValuesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const CoreValueCard = styled.div`
  text-align: center;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  h3 {
    font-size: 20px;
    margin-bottom: 10px;
    color: #333;
  }

  p {
    font-size: 16px;
    color: #666;
  }

  &:hover {
    transform: translateY(-5px);
  }
`;

const TeamSection = styled.section`
  padding: 50px;
  background-color: #f5f5f5;
  text-align: center;

  p {
    font-size: 18px;
    line-height: 1.6;
    max-width: 900px;
    margin: 0 auto;
    color: #666;
  }
`;
