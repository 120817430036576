import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import Services from './pages/Services';
import About from './pages/About';
import Projects from './pages/Projects';
import Contact from './pages/Contact';
import ProjectDetail1 from './pages/ProjectDetail1'; // Import Project Detail 1
import ProjectDetail2 from './pages/ProjectDetail2'; // Import Project Detail 2

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/about" element={<About />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/projects/kasesalu-tee-1" element={<ProjectDetail1 />} /> {/* Route for Project 1 */}
        <Route path="/projects/kasesalu-tee-3" element={<ProjectDetail2 />} /> {/* Route for Project 2 */}
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
