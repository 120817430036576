import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import heroVideo from '../assets/hero-video.mp4'; // Replace with actual path to your video or test with an external URL
import parallaxImage from '../assets/parallax-image.jpg'; // Add the image for the first parallax effect
import parallaxImage2 from '../assets/parallax-image2.jpg'; // Add the image for the second parallax effect
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <HomeContainer>
    {/* Hero Section with Video Background */}
    <HeroSection>
      <VideoBg autoPlay loop muted playsInline>
        <source src={heroVideo} type="video/mp4" />
        {/* Fallback image if the video doesn't load */}
        Your browser does not support the video tag.
      </VideoBg>
      <HeroOverlay />
      <HeroContent>
        <motion.h1 initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
          Teie Tulevase Kodu Loojad
        </motion.h1>
        <motion.p initial={{ y: '100px', opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 1 }}>
          Toro Kinnisvara – Kinnisvaraarendus ja konsultatsioonid Eestis
        </motion.p>
        <HeroButton
          as={Link}
          to="/projects"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          Tutvu Projektidega
        </HeroButton>
      </HeroContent>
    </HeroSection>

    {/* Highlight Section with Framer Motion */}
    <HighlightSection>
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        viewport={{ once: true }}
      >
       <HighlightContent>
  <h2>Pakume <HighlightAccent>innovatsiooni ja kvaliteedi</HighlightAccent> täiuslikku tasakaalu</h2>
  <p>
    Toro Kinnisvara on pühendunud kaasaegsete kodude loomisele, mis peegeldavad nii funktsionaalsust kui ka esteetilist 
    ilu. Meie põhifookus on pakkuda klientidele kodusid, mis toetavad nende elustiili ja tagavad mugavuse igal sammul.
  </p>
  <p>
    Viimase kahe aastakümne jooksul oleme edukalt arendanud üle 50 kinnisvaraprojekti ja ehitanud enam kui 500 kaasaegset 
    elamispinda. Meie kogemused ja kirg kvaliteedi vastu on taganud, et kõik meie projektid vastavad kõrgeimatele standarditele.
  </p>
  <p>
    Meie pühendumus jätkusuutlikkusele ja keskkonnateadlikele lahendustele tähendab, et loome elukeskkondi, mis on nii 
    tulevikku vaatavad kui ka vastutustundlikud.
  </p>
</HighlightContent>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        viewport={{ once: true }}
      >
        <HighlightImages>
          <img src="https://via.placeholder.com/600x400" alt="Highlight 1" />
          <img src="https://via.placeholder.com/600x400" alt="Highlight 2" />
          <img src="https://via.placeholder.com/600x400" alt="Highlight 3" />
          <img src="https://via.placeholder.com/600x400" alt="Highlight 4" />
        </HighlightImages>
      </motion.div>
    </HighlightSection>

      {/* Parallax Section 1 */}
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1 }}
        viewport={{ once: true }}
      >
        <ParallaxSection>
          <ParallaxContent>
            <h2>Kvaliteet ja Uuenduslikkus</h2>
            <p>
              Meie eesmärk on pakkuda kvaliteetseid ja kaasaegseid kinnisvaraarenduslahendusi, mis vastavad tänapäeva nõudmistele. Ükskõik
              kas arendame uusi elamuid või renoveerime ajaloolisi hooneid, meie keskmes on alati innovatsioon ja kliendi rahulolu.
            </p>
          </ParallaxContent>
        </ParallaxSection>
      </motion.div>

      {/* Services Section */}
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        viewport={{ once: true }}
      >
        <ServicesSection>
          <SectionTitle>Meie Teenused</SectionTitle>
          <ServicesGrid>
            <ServiceCard>
              <ServiceIcon>🏠</ServiceIcon>
              <h3>Kinnisvaraarendus</h3>
              <p>Kõige paremad lahendused teie kinnisvarale</p>
            </ServiceCard>
            <ServiceCard>
              <ServiceIcon>📷</ServiceIcon>
              <h3>Kinnisvarafotod</h3>
              <p>Professionaalsed fotod teie kinnisvarast</p>
            </ServiceCard>
            <ServiceCard>
              <ServiceIcon>🔑</ServiceIcon>
              <h3>Maakler</h3>
              <p>Usaldusväärsed kinnisvaratehingud</p>
            </ServiceCard>
            <ServiceCard>
              <ServiceIcon>🚁</ServiceIcon>
              <h3>Droonifotod</h3>
              <p>Unikaalsed vaated kõrgelt</p>
            </ServiceCard>
            <ServiceCard>
              <ServiceIcon>💼</ServiceIcon>
              <h3>Konsultatsioon</h3>
              <p>Isiklik kinnisvaranõustamine</p>
            </ServiceCard>
          </ServicesGrid>
        </ServicesSection>
      </motion.div>

      {/* Projects Section */}
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        viewport={{ once: true }}
      >
        <ProjectsSection>
          <SectionTitle>Meie Projektid</SectionTitle>
          <ProjectsGrid>
            <ProjectCard>
              <ProjectImage src={require('../assets/house-1/1.jpg')} alt="Kasesalu tee 1" />
              <h3>Kasesalu tee 1</h3>
              <p>Kaasaegne 5-toaline eramaja Haage järve lähedal</p>
              <ProjectButton as={Link} to="/projects">Loe rohkem</ProjectButton>
            </ProjectCard>
            <ProjectCard>
              <ProjectImage src={require('../assets/house-2/1.jpg')} alt="Kasesalu tee 3" />
              <h3>Kasesalu tee 3</h3>
              <p>Kaunis 5-toaline eramaja looduse keskel</p>
              <ProjectButton as={Link} to="/projects">Loe rohkem</ProjectButton>
            </ProjectCard>
          </ProjectsGrid>
        </ProjectsSection>
      </motion.div>

      {/* Contact Section */}
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        viewport={{ once: true }}
      >
        <ContactSection>
          <SectionTitle>Võta Meiega Ühendust</SectionTitle>
          <ContactForm>
            <FormInput type="text" placeholder="Nimi" />
            <FormInput type="email" placeholder="Email" />
            <FormTextArea placeholder="Sõnum"></FormTextArea>
            <FormButton type="submit">Saada</FormButton>
          </ContactForm>
        </ContactSection>
      </motion.div>

      {/* Parallax Section 2 behind Contact Section */}
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1 }}
        viewport={{ once: true }}
      >
        <ParallaxSection2>
          <ParallaxContent>
            <h2>Kinnisvara lahendused just Teile</h2>
            <p>
              Võtke meiega ühendust ja uurige, kuidas saame teid aidata kinnisvaraalaste lahendustega. Üheskoos leiame teie unistuste kodu.
            </p>
          </ParallaxContent>
        </ParallaxSection2>
      </motion.div>
    </HomeContainer>
  );
};

export default Home;

/* Styling */
const HomeContainer = styled.div`
  font-family: 'Arial', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeroSection = styled.div`
  position: relative;
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  overflow: hidden;
`;

const VideoBg = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
`;


const HeroOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
`;

const HeroContent = styled.div`
  color: #fff;
  text-align: center;

  h1 {
    font-size: 60px;
    margin-bottom: 20px;
  }

  p {
    font-size: 24px;
    margin-bottom: 40px;
  }
`;

const HeroButton = styled(motion.button)`
  padding: 12px 40px;
  font-size: 18px;
  background-color: #ff7b00;
  border: none;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ff9500;
  }
`;

/* Highlight Section */
const HighlightSection = styled.section`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 80px 100px;
  background-color: #f5f5f5;
`;

const HighlightContent = styled.div`
  width: 60%;
  margin: 20px; 
  h2 {
    font-size: 36px;
    margin-bottom: 20px;
  }

  p {
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 20px;
  }
`;

const HighlightAccent = styled.span`
  color: #ff7b00;
  font-weight: bold;
`;

const HighlightImages = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;
  width: 100%;
  img {
    width: 100%;
    border-radius: 8px;
    object-fit: cover;
  }
`;

/* Parallax Section 1 */
const ParallaxSection = styled.section`
  height: 400px;
  background-image: url(${parallaxImage});
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const ParallaxSection2 = styled.section`
  height: 400px;
  background-image: url(${parallaxImage2});
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const ParallaxContent = styled.div`
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 10px;
`;

/* Services Section */
const ServicesSection = styled.section`
  width: 100%;
  padding: 80px 20px;
  background-color: #f5f5f5;
  text-align: center;
`;

const SectionTitle = styled.h2`
  font-size: 36px;
  margin-bottom: 50px;
`;

const ServicesGrid = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 30px;
`;

const ServiceCard = styled.div`
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  width: 250px;
  text-align: center;
  transition: transform 0.3s ease;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);

  &:hover {
    transform: translateY(-10px);
  }

  h3 {
    margin-top: 20px;
    font-size: 24px;
  }

  p {
    margin-top: 10px;
    font-size: 16px;
    color: #666;
  }
`;

const ServiceIcon = styled.div`
  font-size: 50px;
  color: #ff7b00;
`;

/* Projects Section */
const ProjectsSection = styled.section`
  width: 100%;
  padding: 80px 20px;
  background-color: #fff;
  text-align: center;
`;

const ProjectsGrid = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 30px;
`;

const ProjectCard = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  transition: transform 0.3s ease;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);

  &:hover {
    transform: translateY(-10px);
  }

  h3 {
    margin-top: 20px;
    font-size: 24px;
  }

  p {
    margin-top: 10px;
    font-size: 16px;
    color: #666;
  }
`;

const ProjectImage = styled.img`
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 8px;
`;

const ProjectButton = styled(Link)`
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #ff7b00;
  color: white;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ff9500;
  }
`;

/* Contact Section */
const ContactSection = styled.section`
  width: 100%;
  padding: 80px 20px;
  background-color: #f5f5f5;
  text-align: center;
`;

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const FormInput = styled.input`
  width: 300px;
  padding: 15px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
`;

const FormTextArea = styled.textarea`
  width: 300px;
  padding: 15px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  height: 150px;
`;

const FormButton = styled.button`
  padding: 15px 30px;
  font-size: 18px;
  background-color: #ff7b00;
  border: none;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #ff9500;
  }
`;
