import React from 'react';
import styled from 'styled-components';
import logo1 from '../assets/logo1.png'; // Import your chosen logo
import { FaPhone, FaEnvelope, FaFacebook, FaInstagram } from 'react-icons/fa'; // Social and contact icons

const Footer = () => {
  return (
    <FooterWrapper>
      <FooterContainer>
        {/* Left Section - Logo */}
        <FooterSection>
          <Logo src={logo1} alt="Toro Kinnisvara Logo" />
        </FooterSection>

        {/* Middle Section - Contact Info */}
        <FooterSection>
          <FooterInfo>
            <FooterContact>
              <FaPhone /> <a href="tel:+53541303">53541303</a>
            </FooterContact>
            <FooterContact>
              <FaEnvelope /> <a href="mailto:filipp.bulgakov@gmail.com">filipp.bulgakov@gmail.com</a>
            </FooterContact>
          </FooterInfo>
        </FooterSection>

        {/* Right Section - Social Media */}
        <FooterSection>
          <SocialMedia>
            <SocialLink href="#">
              <FaFacebook />
            </SocialLink>
            <SocialLink href="#">
              <FaInstagram />
            </SocialLink>
          </SocialMedia>
        </FooterSection>
      </FooterContainer>

      {/* Copyright */}
      <Copyright>&copy; 2024 Toro Kinnisvara. Kõik õigused kaitstud.</Copyright>
    </FooterWrapper>
  );
};

export default Footer;

/* Styling */
const FooterWrapper = styled.footer`
  background-color: #333;
  color: white;
  padding: 10px 0px;
  width: 100%;
  position: relative;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: auto; /* Ensures footer stays at the bottom */
  z-index: 1;
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  flex-wrap: wrap;
  text-align: center;
  margin: 0 auto;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
`;

const FooterSection = styled.div`
  flex: 1;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const Logo = styled.img`
  width: 150px;
  height: auto;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }
`;

const FooterInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FooterContact = styled.p`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  margin: 5px 0;

  a {
    text-decoration: none;
    color: white;
    transition: color 0.3s ease;

    &:hover {
      color: #ff7b00;
    }
  }
`;

const SocialMedia = styled.div`
  display: flex;
  gap: 20px;
`;

const SocialLink = styled.a`
  font-size: 24px;
  color: white;
  transition: color 0.3s ease;

  &:hover {
    color: #ff7b00;
  }
`;

const Copyright = styled.p`
  text-align: center;
  font-size: 14px;
  color: #aaa;
  margin-top: 20px;
  width: 100%;
`;
