import React from 'react';
import styled from 'styled-components';
import { FaPhone, FaEnvelope, FaMapMarkerAlt, FaUserTie } from 'react-icons/fa';
import { motion } from 'framer-motion';

const Contact = () => {
  return (
    <ContactContainer>
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <ContactTitle>Võta meiega ühendust</ContactTitle>
      </motion.div>

      <ContactContent>
        {/* Contact Information */}
        <motion.div
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          <InfoSection>
            <ContactInfoTitle>Kontaktinfo</ContactInfoTitle>
            <ContactCard>
              <IconWrapper>
                <FaUserTie />
              </IconWrapper>
              <div>
                <ContactName>Filipp Bulgakov</ContactName>
                <ContactRole>Projektijuht</ContactRole>
              </div>
            </ContactCard>

            <ContactCard>
              <IconWrapper>
                <FaPhone />
              </IconWrapper>
              <ContactText>
                <a href="tel:+53541303">53541303</a>
              </ContactText>
            </ContactCard>

            <ContactCard>
              <IconWrapper>
                <FaEnvelope />
              </IconWrapper>
              <ContactText>
                <a href="mailto:filipp.bulgakov@gmail.com">filipp.bulgakov@gmail.com</a>
              </ContactText>
            </ContactCard>

            <ContactCard>
              <IconWrapper>
                <FaMapMarkerAlt />
              </IconWrapper>
              <ContactText>Tartu, Eesti</ContactText>
            </ContactCard>
          </InfoSection>
        </motion.div>

        {/* Contact Form */}
        <motion.div
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8, delay: 0.4 }}
        >
          <FormSection>
            <ContactFormTitle>Saada meile sõnum</ContactFormTitle>
            <ContactForm>
              <FormInput type="text" placeholder="Teie nimi" />
              <FormInput type="email" placeholder="Teie e-mail" />
              <FormTextArea placeholder="Teie sõnum" />
              <SubmitButton>Saada</SubmitButton>
            </ContactForm>
          </FormSection>
        </motion.div>
      </ContactContent>

      {/* Google Map Placeholder */}
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.6 }}
      >
        <MapPlaceholder>
          <p>Siia saab paigutada Google Maps iframe või pildi ettevõtte asukohast</p>
        </MapPlaceholder>
      </motion.div>
    </ContactContainer>
  );
};

export default Contact;

/* Styling */
const ContactContainer = styled.div`
  padding: 50px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: 'Roboto', sans-serif;
`;

const ContactTitle = styled.h2`
  font-size: 42px;
  margin-bottom: 40px;
  font-family: 'Poppins', sans-serif;
  color: #333;
  text-align: center;
`;

const ContactContent = styled.div`
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: flex-start; /* Align content at the top */
  flex-wrap: wrap;
  margin-bottom: 40px;
`;

const InfoSection = styled.div`
  flex: 0.45;
`;

const ContactInfoTitle = styled.h3`
  font-size: 28px;
  margin-bottom: 20px;
  font-family: 'Poppins', sans-serif;
  color: #ff7b00;
`;

const ContactCard = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const IconWrapper = styled.div`
  font-size: 28px;
  color: #ff7b00;
  margin-right: 15px;
`;

const ContactName = styled.h4`
  font-size: 20px;
  margin: 0;
`;

const ContactRole = styled.p`
  margin: 0;
  font-size: 14px;
  color: #777;
`;

const ContactText = styled.p`
  font-size: 18px;
  margin: 0;

  a {
    text-decoration: none;
    color: #333;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const FormSection = styled.div`
  flex: 0.55;
  display: flex;
  justify-content: center; /* Center form horizontally */
`;

const ContactFormTitle = styled.h3`
  font-size: 28px;
  margin-bottom: 20px;
  font-family: 'Poppins', sans-serif;
  color: #ff7b00;
  text-align: center;
`;

const ContactForm = styled.form`
  width: 100%;
  max-width: 500px; /* Set a max width for the form to center it */
  display: flex;
  flex-direction: column;
`;

const FormInput = styled.input`
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
`;

const FormTextArea = styled.textarea`
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  height: 150px;
`;

const SubmitButton = styled.button`
  padding: 15px;
  background-color: #ff7b00;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #e06a00;
  }
`;

const MapPlaceholder = styled.div`
  width: 100%;
  height: 400px;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #888;
  text-align: center;
`;
