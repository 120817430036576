import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import Arendus from '../assets/Arendus.jpg';  // Replace with correct paths
import Droon from '../assets/Droon.jpg';
import Maakler from '../assets/Maakler.jpg';
import Konsultatsioon from '../assets/Konsultatsioon.jpg';
import Pildistamine from '../assets/Pildistamine.jpg';
import { FaCheckCircle } from 'react-icons/fa'; // For icons in front of points
import heroImage from '../assets/hero-services.jpg';  // Replace with actual path to your hero image

const Services = () => {
  const servicesData = [
    {
      id: 1,
      title: 'Kinnisvaraarendus',
      description: 'Meie spetsialiseeritud meeskond aitab teil arendada ja kujundada kõige paremaid lahendusi kinnisvara turul. Pakume terviklahendusi alates planeerimisest kuni ehituse lõppemiseni.',
      points: ['Planeerimine', 'Projektijuhtimine', 'Ehitamine ja disain'],
      image: Arendus,
      alt: 'Kinnisvaraarendus',
    },
    {
      id: 2,
      title: 'Droonifotod',
      description: 'Pakume unikaalseid droonifotosid, mis annavad teie kinnisvarale uue perspektiivi. Kõrgtasemel droonifotod aitavad paremini näidata kinnisvara ulatust ja ilu.',
      points: ['Aerofotod', 'Videotuurid', 'Kinnisvara vaated'],
      image: Droon,
      alt: 'Droonifotod',
    },
    {
      id: 3,
      title: 'Maakler',
      description: 'Usaldusväärsed maaklerteenused teie kinnisvaratehingute jaoks. Meie kogenud maaklerid tagavad sujuva ja eduka tehingu igal sammul.',
      points: ['Vahendus', 'Tehingute haldamine', 'Juriidiline nõustamine'],
      image: Maakler,
      alt: 'Maakleriteenused',
    },
    {
      id: 4,
      title: 'Konsultatsioon',
      description: 'Personaalne ja asjatundlik kinnisvaranõustamine, et aidata teil teha teadlikke otsuseid. Meie eksperdid pakuvad nõu investeeringute, ostu ja müügi kohta.',
      points: ['Kinnisvarainvesteeringud', 'Juriidilised konsultatsioonid', 'Turuanalüüs'],
      image: Konsultatsioon,
      alt: 'Konsultatsioon',
    },
    {
      id: 5,
      title: 'Kinnisvara pildistamine',
      description: 'Meie professionaalsed fotograafid tagavad, et teie kinnisvara paistaks parimas valguses. Kõrgekvaliteedilised pildid aitavad müüa ja rentida kinnisvara kiiremini ja efektiivsemalt.',
      points: ['Sisekujunduse fotod', 'Õuealad ja välisfotod', 'Professionaalne retušeerimine'],
      image: Pildistamine,
      alt: 'Kinnisvara pildistamine',
    },
  ];

  return (
    <>
      {/* Hero Section */}
      <HeroSection>
        <HeroOverlay />
        <HeroContent>
          <motion.h1 initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
            Meie Teenused
          </motion.h1>
          <motion.p initial={{ opacity: 0, y: 50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 1 }}>
            Pakume laia valikut professionaalseid kinnisvarateenuseid, mis aitavad teid igal sammul - 
            alates planeerimisest kuni ehitamise ja lõpliku müügini.
          </motion.p>
        </HeroContent>
      </HeroSection>

      {/* Services Section */}
      <ServicesContainer>
        {servicesData.map((service, index) => (
          <motion.div
            key={service.id}
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
          >
            <ServiceCard reverse={index % 2 !== 0}>
              <ServiceImageContainer reverse={index % 2 !== 0}>
                <ServiceImage src={service.image} alt={service.alt} />
              </ServiceImageContainer>
              <ServiceContent>
                <motion.h3 whileHover={{ scale: 1.05 }}>{service.title}</motion.h3>
                <TitleDivider />
                <p>{service.description}</p>
                <ul>
                  {service.points.map((point, i) => (
                    <motion.li key={i} whileHover={{ x: 10 }}>
                      <FaCheckCircle style={{ color: '#ff7b00', marginRight: '10px' }} />
                      {point}
                    </motion.li>
                  ))}
                </ul>
              </ServiceContent>
            </ServiceCard>
          </motion.div>
        ))}
      </ServicesContainer>
    </>
  );
};

export default Services;

/* Styling */
const HeroSection = styled.section`
  width: 100%;
  height: 50vh;
  background-image: url(${heroImage});
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  position: relative;
`;

/* Added a dark overlay to improve text readability */
const HeroOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust opacity to improve readability */
`;

const HeroContent = styled.div`
  padding: 0 20px;
  z-index: 1; /* Ensures content appears on top of the overlay */

  h1 {
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  p {
    font-size: 18px;
    line-height: 1.6;
  }
`;

const ServicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 50px;
  background-color: #f7f7f7;
`;

const ServiceCard = styled.div`
  display: flex;
  justify-content: ${(props) => (props.reverse ? 'flex-end' : 'flex-start')};
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex-direction: ${(props) => (props.reverse ? 'row-reverse' : 'row')};
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const ServiceImageContainer = styled.div`
  flex: 0.4;
  background-color: #f0f0f0;
`;

const ServiceImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ServiceContent = styled.div`
  flex: 0.6;
  padding: 30px;

  h3 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #333;
    transition: scale 0.3s ease;
  }

  p {
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
  }

  ul {
    list-style: none;
    padding-left: 0;

    li {
      font-size: 14px;
      margin-bottom: 10px;
      color: #555;
      display: flex;
      align-items: center;
      transition: transform 0.3s ease;
    }
  }
`;

/* Creative Divider under Titles */
const TitleDivider = styled.div`
  width: 50px;
  height: 4px;
  background-color: #ff7b00;
  margin: 10px 0 20px 0;
  border-radius: 2px;
`;
