import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import { motion } from 'framer-motion';
import logo3 from '../assets/logo3.png'; // Import your logo image

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Nav>
      <NavContainer>
        {/* Left-side navigation */}
        <NavMenu isOpen={isOpen}>
          <NavItem>
            <NavLink to="/">Avaleht</NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/services">Teenused</NavLink>
          </NavItem>
        </NavMenu>

        {/* Logo in the center */}
        <LogoContainer>
          <Logo src={logo3} alt="Toro Kinnisvara Logo" />
        </LogoContainer>

        {/* Right-side navigation */}
        <NavMenu isOpen={isOpen}>
          <NavItem>
            <NavLink to="/projects">Projektid</NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/about">Meist</NavLink> {/* New "Meist" (About Us) link */}
          </NavItem>
          <NavItem>
            <NavLink to="/contact">Kontakt</NavLink>
          </NavItem>
        </NavMenu>

        {/* Mobile Hamburger Menu */}
        <MobileIcon onClick={toggleMenu}>
          {isOpen ? <FaTimes /> : <FaBars />}
        </MobileIcon>

        {/* Dropdown Menu for mobile */}
        {isOpen && (
          <MobileMenu>
            <MobileMenuItem onClick={toggleMenu}>
              <NavLink to="/">Avaleht</NavLink>
            </MobileMenuItem>
            <MobileMenuItem onClick={toggleMenu}>
              <NavLink to="/services">Teenused</NavLink>
            </MobileMenuItem>
            <MobileMenuItem onClick={toggleMenu}>
              <NavLink to="/projects">Projektid</NavLink>
            </MobileMenuItem>
            <MobileMenuItem onClick={toggleMenu}>
              <NavLink to="/about">Meist</NavLink> {/* "Meist" link added to mobile */}
            </MobileMenuItem>
            <MobileMenuItem onClick={toggleMenu}>
              <NavLink to="/contact">Kontakt</NavLink>
            </MobileMenuItem>
          </MobileMenu>
        )}
      </NavContainer>
    </Nav>
  );
};

export default Navbar;

/* Styling */
const Nav = styled.nav`
  background: #fff;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`;

const NavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  padding: 0 50px;
  position: relative;

  @media (max-width: 768px) {
    padding: 0 20px;
  }
`;

const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  transition: all 0.3s ease;

  @media (max-width: 768px) {
    display: none;
  }
`;

const NavItem = styled.li`
  margin: 0 20px;

  &:last-child {
    margin-right: 0;
  }
`;

const NavLink = styled(Link)`
  text-decoration: none;
  color: #333;
  font-weight: 500;
  transition: color 0.3s ease;

  &:hover {
    color: #ff7b00;
  }
`;

const LogoContainer = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

const Logo = styled.img`
  width: 120px;
  height: auto;
  cursor: pointer;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }
`;

const MobileIcon = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
    font-size: 2rem;
    cursor: pointer;
    color: #333;
  }
`;

const MobileMenu = styled.ul`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 80px;
  right: 0;
  width: 100%;
  height: 100vh;
  background: #fff;
  list-style: none;
  align-items: center;
  justify-content: center;
  z-index: 999;
  transition: all 0.3s ease;
`;

const MobileMenuItem = styled.li`
  margin: 20px 0;
  font-size: 1.5rem;
`;
